import '../scss/main.scss';

import { AppProps } from 'next/app';
import { useRouter } from 'next/router';

import React from 'react';
import { useEffect, useState } from 'react';

import { v4 as uuidV4 } from 'uuid';

import { ProgressBarProvider } from '../context/ProgressBarContext';
import { AlertProvider } from '../context/AlertContext';

import { SideBarProvider } from '../context/SideBarContext';

import { useAuth0, Auth0Provider } from '@auth0/auth0-react';

import { useCookie } from '../hooks/useCookie';
import * as constants from '../constants';
import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { ApolloProvider } from '@apollo/client';
import { initializeApollo, useApollo } from '@root/graphql/apollo/client';
import { IS_SERVER } from '@root/utils';
import { getIntercomHashPromise, getUserProfilePromise } from '@root/graphql/operations';
import { TGetUserProfileQueryResult } from '@root/graphql/generated/operations';

declare global {
  interface Window {
    Intercom: any;
    APP_ID: string;
  }
}

function MyAuthenticatedApp({ Component, pageProps }: any) {
  const { isAuthenticated, isLoading, user, loginWithRedirect, getAccessTokenSilently } = useAuth0();
  const [authToken, setAuthToken] = useCookie(constants.cookie.token);
  const [anonymous, setAnonymous] = useCookie(constants.cookie.anonymous);
  const [apolloClient, setApolloClient] = useState<ApolloClient<NormalizedCacheObject>>(() => initializeApollo());
  const [intercomHash, setIntercomHash] = useState<null | string>(null);
  const [userProfile, setUserProfile] = useState<null | TGetUserProfileQueryResult['getUserProfile']>(null);

  useEffect(() => {
    if (!isLoading && !isAuthenticated && authToken) {
      loginWithRedirect();
    }
  }, [isLoading, isAuthenticated, authToken]);

  useEffect(() => {
    if (isLoading) return;
    if (isAuthenticated) return;

    setApolloClient(() => {
      let tempuserid = anonymous;
      const initialState = {};

      if (!tempuserid) {
        tempuserid = uuidV4();
        setAnonymous(tempuserid);
      }

      return initializeApollo(initialState, {}, true);
    });
  }, [isAuthenticated, isLoading, anonymous]);

  useEffect(() => {
    async function setupApollo() {
      let token: string = '';
      const initialState = {};

      if (!isLoading && isAuthenticated) {
        token = await getAccessTokenSilently({ audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE });
        const apolloClient = initializeApollo(initialState, { authorization: `Bearer ${token}` }, true);
        setAuthToken(token);
        setApolloClient(apolloClient);

        const { data: hashData } = await getIntercomHashPromise(apolloClient);
        const { data: userData } = await getUserProfilePromise(apolloClient);

        setIntercomHash(hashData.getIntercomHash || '');
        setUserProfile(userData?.getUserProfile);
      }
    }

    setupApollo();
  }, [isAuthenticated, isLoading, getAccessTokenSilently]);

  useEffect(() => {
    function setupIntercom() {
      if (!userProfile) {
        window.Intercom('boot', {
          app_id: window.APP_ID,
          api_base: 'https://api-iam.intercom.io',
        });
      } else {
        window.Intercom('boot', {
          app_id: window.APP_ID,
          api_base: 'https://api-iam.intercom.io',
          name: userProfile.full_name,
          email: userProfile.email,
          user_id: userProfile.id,
          avatar: userProfile.avatar,
          ...(intercomHash ? { user_hash: intercomHash } : {}),
        });
      }
    }

    setupIntercom();
  }, [intercomHash, userProfile]);

  return (
    <ApolloProvider client={apolloClient}>
      <AlertProvider>
        <ProgressBarProvider>
          <SideBarProvider>
            <Component {...pageProps} />
          </SideBarProvider>
        </ProgressBarProvider>
      </AlertProvider>
    </ApolloProvider>
  );
}

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();

  let redirectUri = process.env.NEXT_PUBLIC_ORIGIN + router.pathname;
  if (!IS_SERVER) redirectUri = window.location.origin;

  return (
    <Auth0Provider
      domain={process.env.NEXT_PUBLIC_AUTH0_DOMAIN as string}
      clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID as string}
      audience={process.env.NEXT_PUBLIC_AUTH0_AUDIENCE}
      redirectUri={redirectUri}
      useRefreshTokens={true}
      // cacheLocation="localstorage"
    >
      <MyAuthenticatedApp Component={Component} pageProps={pageProps} />
    </Auth0Provider>
  );
}

export default MyApp;
